import { AxiosResponse } from "axios";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { reclaim } from "../reclaim-api";
import { User, UserFeature, UserFeatureFlag, UserSettings, UserTrait } from "../reclaim-api/Users";
import { mockDate } from "../utils/dev";
import {
  getLocalStorage,
  getSessionStorage,
  removeLocalStorage,
  removeSessionStorage,
  setLocalStorage,
  setSessionStorage,
} from "../utils/local-storage";
import { diff, patch, walk } from "../utils/objects";
import { browser } from "../utils/platform";
import { useAnalyticsContext } from "./AnalyticsContext";

type UserContextValue = [UserContextState, UserContextActions?];

interface UserContextRedirectState {
  uri: string;
  state?: {
    params?: { [key: string]: string };
    [key: string]: unknown;
  };
}

export interface UserContextState {
  status: "init" | "loading" | "failed" | "error" | "ok";
  isAuthenticated: boolean;
  user: User | null;
  redirect?: UserContextRedirectState;
}

export interface UserContextActions {
  load(refresh?: boolean): Promise<User | undefined>;
  logout(): void;
  delete(): Promise<void>;
  reset(): void;
  setStatus(status: string, isAuthenticated?: boolean): void;
  saveUser(user: User): Promise<User | undefined>;
  setFeature(feature: keyof UserSettings, value?: any): Promise<User | undefined>;
  toggleFeature(feature: keyof UserFeatureFlag, enabled?: boolean): Promise<User | undefined>;
  addTrait(trait: UserTrait): Promise<User>;
}

const defaultState: UserContextState = {
  status: "init",
  isAuthenticated: false,
  user: getLocalStorage("auth.user", null),
};

function useUserState(initialState: UserContextState = defaultState): UserContextValue {
  const [state, setState] = useState<UserContextState>(initialState);

  const {
    state: { sentry, segment },
  } = useAnalyticsContext();

  const actions = useMemo(
    () => ({
      load: async (refresh?: boolean) => {
        console.log("Loading user", refresh);

        setState((prevState: UserContextState) => {
          if (!!refresh) return prevState;
          return {
            ...prevState,
            status: "loading",
          };
        });

        try {
          const user = await reclaim.users.getCurrentUser();
          if (!user) {
            console.warn("Current user is null");

            setState((prevState: UserContextState) => {
              return {
                ...prevState,
                isAuthenticated: false,
                status: "failed",
              };
            });

            return state.user;
          }

          setLocalStorage("auth.user", user);
          removeSessionStorage("auth.reauth");

          // Update user id in analytics
          segment?.identify(user.trackingCode);
          sentry?.setUser({ id: user.trackingCode });

          // Dev time-travel (mock global Date w/ offset)
          if ("production" !== process.env.NODE_ENV && !!user.timestampOffsetMs) {
            console.log("Time-travel offset", user.timestampOffsetMs);
            mockDate(user.timestampOffsetMs);
          }

          setState((prevState: UserContextState) => {
            return {
              ...prevState,
              user,
              isAuthenticated: true,
              status: "ok",
            };
          });

          return user;
        } catch (err) {
          console.warn("Auth error", err);

          setState((prevState: UserContextState) => {
            return {
              ...prevState,
              isAuthenticated: false,
              status: "failed",
            };
          });
        }

        return state.user;
      },

      logout: () => {
        setState((prevState: UserContextState) => ({
          ...prevState,
          status: "loading",
        }));

        // clear user data
        removeLocalStorage("auth.user");
        removeSessionStorage("auth.redirect");
        removeSessionStorage("auth.reauth");

        // clear Sentry
        sentry?.configureScope(function (scope) {
          scope.setUser({});
        });

        // clear Segment
        segment?.track("User Logged Out", {
          category: "User",
          nonInteraction: 1,
        });

        try {
          setState((prevState) => {
            return {
              ...prevState,
              user: null,
              isAuthenticated: false,
              status: "ok",
            };
          });
          reclaim.users.logout();
        } catch (err) {
          console.error("Logout failed", err);
          setState((prevState) => {
            return {
              ...prevState,
              user: null,
              isAuthenticated: false,
              status: "error",
            };
          });
        }
      },

      delete: async () => {
        setState((prevState) => ({
          ...prevState,
          status: "loading",
        }));

        // clear user data
        removeLocalStorage("auth.user");
        removeSessionStorage("auth.redirect");
        removeSessionStorage("auth.reauth");
        removeSessionStorage("onboarding.data");

        // clear impersonation data
        removeLocalStorage("auth.admin");
        removeSessionStorage("auth.impersonate");
        removeSessionStorage("auth.impersonate.error");

        // clear Sentry
        sentry?.configureScope(function (scope) {
          scope.setUser({});
        });

        // clear Segment
        segment?.track("User Deleted", {
          category: "User",
          nonInteraction: 1,
        });

        try {
          await reclaim.users.deleteCurrentUser();
        } catch (err) {
          console.error("Delete failed", err);
          setState((prevState: UserContextState) => {
            return {
              ...prevState,
              user: null,
              isAuthenticated: false,
              status: "error",
            };
          });
        }
      },

      reset: () => {
        // clear user data
        removeLocalStorage("auth.user");
        removeSessionStorage("auth.redirect");
        removeSessionStorage("auth.reauth");
        removeSessionStorage("onboarding.data");

        // clear impersonation data
        removeLocalStorage("auth.admin");
        removeSessionStorage("auth.impersonate");
        removeSessionStorage("auth.impersonate.error");

        // clear Sentry
        sentry?.configureScope(function (scope) {
          scope.setUser({});
        });

        // clear Segment
        segment?.track("User Reset", {
          category: "User",
          nonInteraction: 1,
        });

        // reset state
        setState(initialState);
      },

      setStatus: (status: UserContextState["status"], isAuthenticated?: boolean) => {
        if (status === state.status && isAuthenticated === state.isAuthenticated) return;

        setState((prevState: UserContextState) => ({
          ...prevState,
          status,
          isAuthenticated: isAuthenticated !== undefined ? isAuthenticated : prevState.isAuthenticated,
        }));
      },

      saveUser: async (user: User) => {
        if (!state.user) {
          console.warn("Cannot save user, user is not loaded");
          return;
        }
        try {
          const payload = diff(state.user, user);
          const res = await reclaim.users.updateCurrentUser(payload);
          setLocalStorage("auth.user", res);
          setState((prevState) => ({
            ...prevState,
            user: res,
          }));
          return res;
        } catch (err) {
          console.error("Failed to update user", state.user, err);
        }
        return state.user;
      },

      // TODO (IW): Figure out how to handle nested settings
      setFeature: async <K extends UserFeature>(feature: K, val: UserSettings[K]) => {
        if (!state.user) {
          console.warn("Cannot set feature, user is not loaded", feature);
          return;
        }
        const payload = { features: patch(feature, val) };
        try {
          const res = await reclaim.users.updateCurrentUser(payload);
          setLocalStorage("auth.user", res);
          setState((prevState) => ({
            ...prevState,
            user: res,
          }));
          return res;
        } catch (err) {
          console.error("Failed to set feature", feature, val, state.user, err);
        }
        return state.user;
      },

      toggleFeature: async (feature: keyof UserFeatureFlag, enabled?: boolean) => {
        if (!state.user) {
          console.warn("Cannot toggle feature, user is not loaded", feature);
          return;
        }
        const payload = {
          features: patch(feature, undefined !== enabled ? enabled : !walk(feature, state.user.features)),
        };
        try {
          const res = await reclaim.users.updateCurrentUser(payload);
          setLocalStorage("auth.user", res);
          setState((prevState) => ({
            ...prevState,
            user: res,
          }));
          return res;
        } catch (err) {
          console.error("Failed to toggle feature", feature, enabled, state.user, err);
        }
        return state.user;
      },

      addTrait: async (trait: UserTrait) => {
        try {
          const res = await reclaim.users.addInterest(trait);
          setState((prevState) => ({
            ...prevState,
            user: res,
          }));
          return res;
        } catch (err) {
          console.error("Failed to add trait", trait, state.user, err);
          throw err;
        }
      },
    }),
    [initialState, segment, sentry, state.isAuthenticated, state.status, state.user]
  );

  return [state, actions];
}

// Takes the default state in case there is no provider higher in the tree
export const UserContext = createContext<UserContextValue>([defaultState]);

export const UserContextProvider: React.FC = (props) => {
  const [state, actions] = useUserState();

  const {
    state: { sentry },
  } = useAnalyticsContext();

  /**
   * Handle 401 responses:
   * 1. Attempt "silent" reauthentication (one time)
   * 2. Set status "failed" to force new login if silent reauth didn't work
   */
  const notAuthenticated = useCallback(
    (res: AxiosResponse<unknown>) => {
      if (/^401$/.test(`${res.status}`)) {
        console.warn("Request failed, user not authenticated");

        const user = getLocalStorage("auth.user", null);
        const attemptedReAuth = getSessionStorage("auth.reauth");

        if (!!user && !attemptedReAuth) {
          console.log("Attempting reauth");

          actions?.setStatus("loading", false);

          const redirect = browser().isBrowser ? `${window.location.pathname}${window.location.search}` : undefined;
          const urlParams = new URLSearchParams(browser().isBrowser ? window.location.search : "");
          const slackTeamId = urlParams.get("slackTeamId");
          const slackUserId = urlParams.get("slackUserId");
          const oauthState = { redirect, slackTeamId, slackUserId };

          setSessionStorage("auth.reauth", true);

          reclaim.users.authRedirect(user.provider || "google", user.email, oauthState);
        } else {
          actions?.setStatus("failed", false);
        }
      }
      return res;
    },
    [actions]
  );

  /**
   * Log server errors in Sentry
   */
  const serverError = useCallback(
    (res: AxiosResponse<unknown>) => {
      if (/^5\d+$/.test(`${res.status}`)) {
        if (!!sentry) {
          const errorScope = new sentry.Scope()
            .setLevel(sentry.Severity.Error)
            .setContext("request_config", res.config as any);

          sentry.captureMessage(`[SERVER ERROR] ${res.status} ${res.statusText}`, errorScope);
        }
      }

      return res;
    },
    [sentry]
  );

  // FIXME (IW): We have `reclaim.client.registerRequestHook`/`reclaim.client.registerRequestHook`,
  // but they stopped working at some point. The client config sets
  // `validateStatus` to always return true, so the `registerResponseHook` only
  // fires on errors. This really needs to get fixed.

  useEffect(() => {
    // register interceptors
    const nextIds = {
      401: reclaim.client.client.interceptors.response.use(notAuthenticated),
      500: reclaim.client.client.interceptors.response.use(serverError),
    };

    // clear interceptors
    return () => {
      Object.values(nextIds).forEach((id) => reclaim.client.client.interceptors.response.eject(id));
    };
  }, [notAuthenticated, serverError]);

  return <UserContext.Provider value={[state, actions]} {...props} />;
};

// Sub-components can use this component. It will pick up the
// `state` and `actions` given by useUserState() higher in the
// component tree.
export function useUserContext() {
  return useContext<UserContextValue>(UserContext);
}

export default UserContext;
